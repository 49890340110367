/**
 * GETTERS
 */
export const APP_DRAWER = "app/APP_DRAWER";
export const APP_PROGRESSBAR = "app/APP_PROGRESSBAR";
export const APP_FILTER_LOADING = "app/APP_FILTER_LOADING";
export const APP_INFO_MESSAGE = "app/APP_INFO_MESSAGE";
export const AUTH_USERNAME = "auth/AUTH_USERNAME";
export const AUTH_USER_ROLES = "auth/USER_ROLES";

/**
 * MUTATIONS
 */
export const MUTATE_APP_DRAWER = "app/MUTATE_APP_DRAWER";
export const MUTATE_USER_STATE = "auth/MUTATE_USER_STATE";
export const MUTATE_APP_PROGRESSBAR = "app/MUTATE_APP_PROGRESSBAR";
export const MUTATE_APP_FILTER_LOADING = "app/MUTATE_APP_FILTER_LOADING";
export const MUTATE_APP_INFO_MESSAGE = "app/MUTATE_APP_INFO_MESSAGE";

/**
 * ACTIONS
 */
export const AUTH_INIT_USER = "auth/AUTH_INIT_USER";
export const AUTH_VERIFY = "auth/AUTH_VERIFY";
export const AUTH_LOGOUT = "auth/AUTH_LOGOUT";
